import React from "react"
import { Helmet } from "react-helmet"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons"
import BlueDots from "../../static/images/support-blue-dots.svg"
import BlueDot from "../../static/images/support-blue-dot.svg"
import BlueCicle from "../../static/images/support-blue-circle.svg"
import BlueDots2 from "../../static/images/support-blue-dots-2.svg"

export default function BlogPost({ data }) {
  const faq = data?.markdownRemark

  if (!faq) return null

  return (
    <Layout>
      <Helmet>
        <title>Support | IBDmate</title>
      </Helmet>
      <div className="layout-support">
        <div className="blue-bg">
          <form>
            <h1>How can we help?</h1>
          </form>
          <div className="vectors-left">
            <img src={BlueDots} id="blue-dots" alt="" />
            <img src={BlueDot} id="blue-dot" alt="" />
          </div>
          <div className="vectors-right">
            <img src={BlueCicle} id="blue-circle" alt="" />
            <img src={BlueDots2} id="blue-dots-2" alt="" />
          </div>
        </div>
        <div className="wrapper content">
          <nav>
            <Link to="/support/">IBDmate support</Link>{" "}
            <FontAwesomeIcon icon={faChevronRight} />{" "}
            <Link to={`/support/${faq.frontmatter.category}/`}>
              {faq.frontmatter.categoryTitle}
            </Link>{" "}
            <FontAwesomeIcon icon={faChevronRight} />
            {faq.frontmatter.question}
          </nav>
          <h2>{faq.frontmatter.question}</h2>
          <div dangerouslySetInnerHTML={{ __html: faq.html }} />
          <Link to={`/support/${faq.frontmatter.category}/`} className="btn">
            Back to {faq.frontmatter.categoryTitle}
          </Link>{" "}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query BlogQuery($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        question
        category
        categoryTitle
      }
    }
  }
`
